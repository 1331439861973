export const consoleSurprise = () => {
  console.log(`
         ________________________
        |                        |
            Hire Junior Devs!
        |________________________|
                \\ (•◡•) / 
                 \\      / 
                   ====
                   ====


Open the dialog at the end of About section to see why you should. 
And then, call me :)
                   `);
};
